.review-bx{
	margin-bottom:1.5rem;
	img{
		width:4.188rem;
		height:4.188rem;
		border-radius:100%;
		object-fit:cover;
		margin-right:1.75rem;
		@include respond ('tab-land'){
			width:4rem;
			height:4rem;
			margin-right:0.438rem;
		}
	}
	.review-content{
		h2{
			font-size:1.5rem;
			@include respond ('tab-land'){
				font-size:1rem;
			}
		}
		p{
			font-size:0.875rem;
			color: #717579;
		}
	}
}
.rating-bx{
	.rating-content{
		text-align:center;
		@include respond ('phone'){
			text-align:unset;
		}
		ul{
			li{
				margin-right:0.313rem;
				svg{
					@include respond('tab-land'){
						width:0.938rem;
						height:0.938rem;
					}
				}
			}
		}
		h2{
			margin-bottom:0.938rem;
			font-size:2rem;
			@include respond ('tab-land'){
				font-size:1.4rem;
			}
			@include respond ('phone'){
				margin-bottom:0;
				margin-right:0.625rem;
				font-size:1rem;
			}
		}
	}
}
.description-bx{
	.description-content{
		padding-right:4.563rem;
		h3{
			margin-bottom:0.75rem;
			margin-top:0;
			font-size:1.313rem;
			@include respond ('phone'){
				font-size:1.2rem;
			}
		}
		p{
			font-size:0.875rem;
		}
		@include respond('tab-land'){
			padding-right:0.625rem;
		}
		.button-bx{
			svg{
				margin-left:0.625rem;
			}
			.btn{
				border-radius:$radius;
			}
		}
		.badge-bx{
			@include respond('phone'){
				.btn-sm{
				  padding: 0.625rem 0.87rem;
				}
			}
		}
	}
}


.drp-profile{
	padding:0 0.938rem;
	.ai-icon{
		padding:0.625rem 0;
	}
}
.review-date{
	font-size:0.875rem;
	color:#717579;
}
