.swiper-action{
	position: absolute;
	right: 1.875rem;
	bottom: 1.875rem;
	z-index: 2;
	display:flex;
	align-items:center;
	@include respond('phone'){
		right: 0.75rem;
		bottom: 0.938rem;
		
		svg{
			width:0.938rem;
			height:0.938rem;
		}
	}
	.swiper-control-area{
		display: flex;
		align-items: center;
		background: #33342DB8;
		color: #fff;
		padding: 0.5rem;
		border-radius:$radius;
		width:6.875rem;
		height:2.5rem;
		
		
		@include respond('phone'){
			svg{
				width:0.563rem;
				height:0.563rem;
			}
			span{
				font-size:0.688rem;
			}
		} 
		.swiper-pagination{
			position:unset;
		}
		.swiper-button-next, .swiper-button-prev{
			position:unset;
			top: auto;
			margin: 0;
			color:$white;
			&:after{
				font-size:1rem;
			}
		}
	}
}
    
.event-card{
	position:relative;
	.event-img{
			border-radius:$radius;
			overflow:hidden;
		&:after{
			position:absolute;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 29.8%, rgba(0, 0, 0, 0.44) 100%);
			content:"";
			top:0;
			left:0;
			width:100%;
			height:100%;

		}
		img{
			width:100%;
			height:31.25rem;
			object-fit:cover;
			@include respond ('phone'){
				height:10.938rem;			}
		}
	}
	.event-content{
		display: inline-block;
		position: absolute;
		bottom: 1.875rem;
		z-index: 0;
		left: 1.25rem;
		@include respond ('phone'){
			left:0.75rem;
			bottom:1.4rem;
		}
		h4{
			font-size:1.125rem;
			color:#fff;
			@include respond('phone'){
				font-size:0.813rem;
			}

		}
	}
	
}
.event-bx{
	.owl-nav{
		display: flex;
		position: absolute;
		bottom: 30px;
		right: 30px;
		align-items: center;
		justify-content: space-between;
		width: 100px;
		color:#C1C1C1;
		background:#33342D;
		padding:8px;
		border-radius:$radius;
	}
}
.gallery-slider{
	margin-top:2.5rem;
	@include respond ('phone'){
		margin-top:1rem;
	}
	.swiper-img{
		img{
			width:100%;
			height:8.75rem;
			object-fit:cover;
			border-radius:$radius;
			border:4px solid transparent;
			@include respond ('phone'){
				height:4.375rem;
			}
		}
	}
	.swiper-slide-thumb-active{
		img{
			border-color:$primary;
		}
	}
	
}
.guest-bx{
	display:flex;
	align-items:center;
	img{
		width:12.875rem;
		height:6rem;
		object-fit: cover;
		border-radius:0.625rem;
		@include respond ('phone'){
			width:5rem;
			
		}
	}
	.carousel {
		width:12.875rem;
		height:6rem;
		border-radius:0.625rem;	
		.carousel-control-prev,
		.carousel-control-next{
			height: 2.375rem;
			width: 2.375rem;
			background: $white;
			margin: 0 0.25rem;
			top: 50%;
			border-radius: 50%;
			transform: translateY(-50%);
				@include respond('phone'){
					display:none;
				}
		}
		@include respond ('phone'){
			width:5rem;
			
		}
	}
	
}

.form-check{
	&.style-2{
		padding-left:0;
	}
	&.style-3{
		padding-left:1.8rem;
	}
}
.search-area1{
	width: 34.5rem;
	border: 1px solid #C2C2C2;
	border-radius:$radius;
	.input-group-text{
		border-radius:$radius;
		padding-right:1.5rem;
		background: #fff;
		@include respond ('laptop'){
			svg{
				width:1.25rem;
				height:1.25rem;
			}
		}
	}
	.form-control{
		border-right: 0;
		border-color: #f5f5f5;
		border-radius:$radius;
		font-size: 0.938rem;
		font-weight: 500;
		
	}
	@include custommq ($max:1600px){
		width:18.75rem;
	}
	@include respond ('tab-land'){
		display:none;
	}
	@include respond ('tab-port'){
			display:none;
	}
	@include respond ('phone-land'){
			display:none;
	}
}
.facilities{
	ul{
		li{
			margin-bottom:1.125rem;
			a{
				color: #2D3134;
				font-weight:500;
				white-space:nowrap;
			}
			svg{
				margin-right:18px;
				@include respond ('laptop'){
					margin-right:0.5rem;
				}
			}
		}
	}
}
.profile-media{
	display: flex;
    justify-content: space-between;
    align-items: center;
	img{
		width:5rem;
		height:5rem;
		object-fit:cover;
		border-radius:100%;
		border:1px solid #EAEAEA;
		margin-right:1rem;
		padding:0.313rem; 
		@include respond('laptop'){
			margin-right:0.938rem;
		}
	}

}

.p-title{
	font-size:1.5rem;
	@include respond ('phone'){
		font-size:1rem;
		white-space:nowrap;
	}
}
[data-container="boxed"] {
	div > span{
		white-space:unset;
	}
}
[data-container="wide-boxed"] {
	.search-area2{
		width:auto;
	}
	
}


// Custom
/* .mySwiper2{
	.swiper-pagination-fraction{
		font-size: 18px;
		color: white;
		margin-bottom: 20px;
	}
} */

.dropdown{
	.dropdown-toggle{
		cursor: pointer;
	}
}