//demo styles
table#example{
    padding: 0rem 0 2rem 0;
}
table.dataTable{
    font-size: 0.875rem;
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding-top: 0.75em;
    padding-bottom: 0.75em;
}



table.dataTable thead th, table.dataTable thead td{
    border-bottom: 0.125rem solid $border-color;
    border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td{
    border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td{
    background: transparent !important;
}

table.dataTable thead th{
    color: $black;
	white-space:nowrap;
	font-size:1.125rem;
	text-transform:capitalize;
    font-weight: 500!important;
	padding: 1.25rem 0.9375rem;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
	@include respond ('laptop'){
		font-size:1rem;
	}
}
.media-bx{
	img{
		width:58px;
		height:58px;
		border-radius:33px;
		object-fit:cover;
	}
}
table.dataTable tbody td{
    padding:10px 14px!important;
	font-size:1rem;
	font-weight: 400;
	border-bottom:0;
	@include respond('phone') {
		padding: 0.5rem 0.3125rem;
	}
	@include respond ('laptop'){
		font-size:0.875rem;
		padding:0.5rem 0.9375rem;
	}
}

table.dataTable tr.selected{
    //color: var(--primary);
}

table.dataTable tfoot th{
    color: $dark;
    font-weight: 600;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
}
/* .dataTables_wrapper .dataTables_paginate .paginate_button{
	border-radius: 12px;
    padding: 12px 20px;
	background:$primary-light;
	color:$primary;
	&:focus,
	&:active,
	&:hover{
		color: $white !important;
		background: $primary;
		border: 0 !important;
		box-shadow: none;
	}
	&.current{
		color: $white !important;
		background: $primary;
		border: 0 !important;
	}
	&.current:hover,
	&.previous:hover,
	&.next:hover{
		background: $primary;
		color: $white !important;
	}
} */
td.text-ov {
    white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate{
	align-items: center;
    display: flex;
	flex-flow:wrap;
	.paginate_button.previous,.paginate_button.next{
		border-radius:$radius;
		padding: 0.7rem 1rem;
		border:1px solid $primary;
		color:$primary!important;
		font-weight:500;
		font-size:0.875rem;
		
		&.current:hover,
		&.previous:hover,
		&.next:hover{
			  color:$white!important;
				background:var(--primary)!important;
				border:1px solid $primary;
		} 
		&.disabled{
			color:var(--primary)!important;
			border:1px solid $primary;
		}
		
	}
	.paginate_button{
		margin: 0 0.438rem!important;
	}
	span{
		.paginate_button{
			height: 2.875rem;
			width: 2.875rem;
			border-radius: $radius;
			padding: 0;
			background: var(--rgba-primary-1) !important;
			display: inline-block;
			text-align: center;
			line-height:2.875rem;
			color:$primary!important;
			margin:0 7px!important;
			
			
			
		
			&:hover,
			&:active{
					color:var(--primary)!important;
					background: var(--rgba-primary-1) !important;
					border: 1px solid transparent;
					box-shadow:none;
					
			}
			
			&.current{
					border:1px solid transparent!important;
					color:#fff!important;	
					background: var(--primary) !important;
						
				&:hover{
					color:#fff!important;	
					border:1px solid transparent!important;
					
				}
			}
			
			/* @include respond ('phone'){
				height:3.125rem;
				width:3.125rem;
				line-height:3.125rem;
			} */
			
		}
	}
}

.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 0.0625rem solid #e2e2e2;
        padding: .3rem 0.5rem;
        color: rgba(113, 93, 93, 1);
        border-radius: 0.3125rem;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 0.9375rem;
		.bootstrap-select{
			width: 5rem;
			margin: 0 0.3125rem;
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}


.rounded-lg{
	min-width:1.875rem;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}

.dataTablesCard{
	background-color:$white;
	border-radius:$radius;
	&.border-no{
		td{
			border-top:0!important;
		}
	}
}

.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: left;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, 
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active{
	color: $body-color !important;
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter, 
.dataTables_wrapper .dataTables_processing, 
.dataTables_wrapper .dataTables_paginate {
    color: $body-color;
	border-radius:1rem;
	padding:0.125rem 0;
	margin-bottom:1.25rem;
}
.paging_simple_numbers.dataTables_paginate {
	padding: 0.3125rem 0.3125rem;
	justify-content:center;
}
.dataTables_wrapper .dataTables_paginate .paginate_button{
	color: $body-color !important;
}



table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: $border-color;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}

.dataTables_wrapper{
	.dataTables_length{
		.bootstrap-select{
			.dropdown-toggle{
				font-size: 0.813rem !important;
				padding: 0.625rem 1rem;
			}
		}
	}
}
