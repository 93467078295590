:root{
	--nav-headbg: #FFFFFF;
	--sidebar-bg: #FFFFFF;
	--headerbg: #F6F6F6;
}

//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-nav-headerbg="#{$name}"][data-theme-version="dark"],
    [data-nav-headerbg="#{$name}"] {
        @if $name != "color_1"  {
			--nav-headbg: #{$color};
			.nav-header{
				.brand-logo{
					.brand-title{
						path{
							fill:$white;
							stroke:$white;
						}
						.logo-k{
							fill:var(--primary);
						}
					}
				}
				.hamburger{
					.line{
						background:$white;
					}
					&.is-active{
						.line{
							background-color:$white!important;
						}
					}
				}
				border-right:1px solid #EEEEEE57;
			}
			
			//special work for color
			&[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}	
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			}
			
		}
	}
}
//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-sibebarbg="#{$name}"][data-theme-version="dark"],
    [data-sibebarbg="#{$name}"] {
        @if $name != "color_1"  {
			--sidebar-bg: #{$color};
			
			/* .hamburger .line{
				background:$white;
			} */
			.menu-toggle .deznav .metismenu li > ul{
				background:$color!important;
			}
			.deznav .metismenu ul a:before{
				background:rgba($white,0.5);
			}
			
			&[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav {
					.metismenu{
						&>li{
							&.mm-active{
								&>a{
									i{
										color:$white;
									}
								}
							}
						}				
					} 
				}
			}
			&[data-sidebar-style="mini"],
			&[data-sidebar-style="modern"],
			&[data-sidebar-style="compact"],
			&[data-sidebar-style="icon-hover"][data-layout="horizontal"],
			&[data-sidebar-style="full"][data-layout="horizontal"]
			{
				.deznav{
					.metismenu li ul{
						background-color: lighten($color: $color, $amount: 10%);
					}
				} 
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:rgba($white,0.15)!important;
				}
			}
			&[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent!important;
				}	
			}
			&[data-sidebar-style="compact"][data-layout="vertical"]{
				.deznav {
					.metismenu {
						&>li {
							a{
								&:hover{
									color:$white;
								}
							}
							&>a{
								&>i{
									background: lighten($color: $color, $amount: 3%)!important;
									color:rgba($white,0.7);
								}
							}
							&.mm-active,&:hover{
								&>a {
									box-shadow:none;
									background:transparent!important;
									color:$white!important;
									i{
										background: lighten($color: $color, $amount: 10%)!important;
										color:$white!important;
									}
								}
							}
						}
					}
				}
			}
			.deznav {
				.metismenu{
					a{
						color:rgba($white,0.8)!important;
					}
					li{
						ul{
							a{
								&:hover,
								&:focus,
								&.mm-active {
									color:$white;
								}
							}
						}
					}
					&>li{
						&>a{
							color:rgba($white,0.85)!important;
							i{
								color:rgba($white,0.85)!important;
							}
						}
						&.mm-active{
							&>a{
								background:rgba($white,0.15)!important;
								color:$white!important;
								i{
									color:$white!important;
								}
								&:before{
									background:$white!important;
									}
							}
						}
					}
					.has-arrow:after{
						border-color: rgba($white,0.85) transparent transparent rgba($white,0.85)!important;
					}					
				} 
				.header-profile > a.nav-link{
					border-color:rgba($white,0.3);
					.header-info{
						span{
							color:$white;
						}
						small{
							color:rgba($white,0.8);
						}
					} 
				}
				.header-profile2{
					.header-content{
						h2,span{
							color:$white!important;
						}
					}
				}
				.copyright{
					h6,p,span{
						color:$white;
					
					}
			
				
				}
			}
			.plus-box{
				background-color: lighten($color: $color, $amount: 10%);
			}
			.copyright{
				color:$black;
			}
			
			
			//special work for color
			&[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{	
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info{
						span{
							color:rgba($black,0.6)!important;
						}
						small{
							color:rgba($black,0.6)!important;
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			}
		}
	}
}

//background for nav header
@each $name, $color in $theme_backgrounds {
	[data-headerbg="#{$name}"][data-theme-version="dark"],
    [data-headerbg="#{$name}"] {
        @if $name != "color_1"  {
			--headerbg: #{$color};
				.search-area2 .input-group-text,
				.search-area2 .form-control,.search-area2 .form-control::placeholder{
					color:$white!important;
				}
				.search-area .input-group-append .input-group-text i{
					color:$white;
				}
			.header-right{
				.search-area2, .form-control,
				.search-area2 .input-group-text{
					background-color: lighten($color: $color, $amount: 10%);
					border-color:lighten($color: $color, $amount: 10%)!important;
					
					i{
						color:$white;
					}
				}
				svg{
					path{
						fill:$white;
					}
				}
				.nav-action{
					background-color: lighten($color: $color, $amount: 10%)!important;
					border-color:lighten($color: $color, $amount: 10%)!important;
				
				}
				.dz-calender{
						background-color: lighten($color: $color, $amount: 10%)!important;
						border-color:lighten($color: $color, $amount: 10%)!important;
					.form-control{
						color:$white;
					}
				}
			}
			.header-left{
				.header-title{
					h1{
						color:$white;
					}
					
				}
			}
	
			
			
			.header-profile > a.nav-link .header-info small, .header-profile > a.nav-link .header-info span{
				color:$white;	
			}
			//special work for color
			&[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					
				}
				.header-right{
					svg{
						path{
							fill:rgba($black,0.4);	
						}
					}
				}
			}	
			
		}
	}
}