[data-theme-version="dark"]{
	.card {
        background-color: $dark-card;
		box-shadow:none;
		border:1px solid $d-border;
    }
	.dropdown-menu {
        background-color: $dark-card;
		box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255,0.1);
		.dropdown-item {
			//color:#777777;
			&.selected,
			&.selected.active,
			&.active,
			&:hover, 
			&:focus {
				background-color: $dark-card;
				color: $white;
			}
		}
    }
	a{
		color:$grey;
	}
	.text-primary{
		//color:$white!important;
	}
	.btn-link g [fill]{
		fill: #fff;
	}
	.btn-light:active, 
	.btn-light:focus, 
	.btn-light:hover{
		color:#000;
	}
	.form-control {
        background-color:$dark-card;
        border-color: $d-border;
        color: $white;
    }
	.modal-content{
		background:$dark-card;
	}
	.modal-footer,
	.modal-header{
		border-color: $d-border;
	}
	.close{
	    font-weight: 400;
		color: $white;
		text-shadow: none;
		
		&:hover{
			color: $white;
		}
	}
	.star-rating .product-review,
	.new-arrival-content .item{
		color: $white;
	}
	.custom-dropdown .dropdown-menu{
		border-color: $d-border;
	}
	.widget-stat .media > span{
	    background: $d-border;
	    border-color: $d-border;
		color:$white;
	}
	.plus-minus-input .custom-btn{
		background: $d-bg;
	    border-color: $d-border;
	}
	.dropdown-divider,
	.size-filter ul li{
		border-color: $d-border;
	}
	.custom-select{
	    border-color: $d-border;
		color: $d-ctl;
		background:$d-bg;
	}
	.nav-tabs{
		border-color: $d-border!important;
	}
	.mail-list .list-group-item.active i {
		color: $white;
	}
	hr{
		border-color: $d-border;
	}
	.grid-col{
		background:$d-bg;
	}
	
	.noUi-target{
		border-color:$d-border;
		border-radius: 0.5rem;
		box-shadow: none;
		
		.noUi-connects{
			background:$d-border;
		}
	}
	.noUi-marker-large,
	.noUi-marker{
		background:$d-border;
	}
	.input-group-text{
		background:$dark-card;
		color:$body-color;
		border-color:$d-border;
	}
	.note-editor.note-frame{
		border-color:$d-border;
		.btn{
			color:$white;
		}
		.note-editing-area .note-editable{
			color:$white;
		}
	}
	.widget-media .timeline .timeline-panel{
		border-color:$d-border; 
	}
	.notification_dropdown .dropdown-menu-right .all-notification{
		border-color:$d-border; 
	}
	#user-activity .nav-tabs .nav-link{
		//border-color:$d-border; 
        &.active{
            background:$d-bg;
            color:$white;
        }
	}
	.list-group-item-action{
		color:$body-color;
	}
	.list-group-item-action:focus, 
	.list-group-item-action:hover, 
	.list-group-item-action:focus{
		background-color:$d-bg; 
		border-color:$d-bg; 
	}
	.list-group-item.active{
		color:$white;
		border-color:var(--primary);
		&:focus, 
		&:hover, 
		&:focus{
			background-color:var(--primary); 
			border-color:var(--primary); 
			color:$white;
		}
	}
	.swal2-popup{
		background:$dark-card;
	}	
	.form-head .btn-outline-primary{
		border-color:$d-border;
	}
	.form-head .btn-outline-primary:hover{
		border-color:var(--primary);
	}
	.review-tab.nav-pills li a.nav-link.active{
		background:transparent;
	}
	.new-arrival-content {
		 h4 {
			a{
				color:$white;
			}
		 }
	}
	.text-black{
		color:$grey!important;
	}
	.abilities-chart .ct-chart .ct-label{
		fill:$white;
	}
	.morris_chart_height text tspan{
		fill:$white;
	}
	.btn-link{
		color:$white;
	}
	.order-bg{
		background:$d-bg;
	}
	.detault-daterange{
			background:$d-bg;
			color:$white;
		.input-group-text{
			background:$dark-card;
			border:0;

		}	
	}
	.dataTablesCard{
		background-color:$dark-card;
	}
	.compose-content .dropzone{
		background:$d-bg;
		.dz-message .dz-button{
			color:$white;
		}
	}
	.daterangepicker{
		background:$d-bg;
		border-color:var(--primary);
		.calendar-table{
			border-color:var(--primary);
			background:$d-bg;
			.table-condensed{
				td{
					&:hover{
						background-color:var(--primary);
						color:$white;
					}
				}
			}
		}
        &:after{
            border-bottom: 0.375rem solid $d-bg;
        }
	}
    
    .daterangepicker select.hourselect
    , .daterangepicker select.minuteselect
    , .daterangepicker select.secondselect
    , .daterangepicker select.ampmselect{
            background: $d-bg;
            border: 0.0625rem solid $d-border;
            color:$white;
    }
    
	.daterangepicker td.off, 
	.daterangepicker td.off.in-range,
	.daterangepicker td.off.start-date, 
	.daterangepicker td.off.end-date{
		background-color:$dark-card;
		&:hover{
			background-color:var(--primary);
			color:$white;			
		}
	}
	.app-fullcalendar{
		.fc-button{
			background-color:$d-bg;
			border-color:var(--primary);
			color:$white;
			text-shadow:none;
			&:hover,&.fc-stat-hover{
				background-color:var(--primary);
			}
		}
	}
	.swal2-popup .swal2-styled:focus {
		outline: 0;
		box-shadow: 0 0 0 0.125rem $d-border, 0 0 0 0.25rem var(--rgba-primary-1);
	}
	.dd-handle{
		border-color:$d-border;
	}
	.menu-toggle .deznav .metismenu li > ul{
		background:$dark-card;
	}
	.header-right .notification_dropdown .nav-link{
		border-color:$d-border;
	}
	.nav-tabs .nav-link{
		&:hover,&.active{
			border-color:var(--primary);
			background: transparent;
			color: white!important;
			//border: 1px solid var(--primary);
		}
	}
	.clockpicker-popover .popover-content{
		background-color:$dark-card;
	}
	.clockpicker-plate{
		background-color:$d-bg;
	}
	.clockpicker-popover .popover-title{
		background-color:$d-bg;
		color:$white;
	}
	.form-wizard .nav-wizard li .nav-link span{
		background-color:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link:after{
		background:$d-bg;
	}
	.form-wizard .nav-wizard li .nav-link.active:after,.form-wizard .nav-wizard li .nav-link.done:after{
		background:var(--primary);
	}
	.form-wizard .nav-wizard li .nav-link.active span
	,.form-wizard .nav-wizard li .nav-link.done span{
		background:var(--primary);
	}
	.check-switch .custom-control-label:after
    , .check-switch .custom-control-label:before{
        border-color:var(--primary);
    }
    .fc-unthemed .fc-today{
        background:$d-bg;
    }
    .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header{
        background: $d-border;
    }
    .picker__box{
        background:$d-bg;
        .picker__button--clear
        , .picker__button--close
        , .picker__button--today{
            background:$dark-card;
            color:$white;
            &:hover{
                &:before{
                    color:$white;
                }
            }
        }
    }
    .picker{
        color:#999;
    }
    .dtp > .dtp-content{
        background:$d-bg;
    }
    .dtp table.dtp-picker-days tr > td > a{
       color: #68686a; 
       &.selected{
           color:$white;
       }
    }
	.order-request tbody tr{
		border-color:$d-border;
	}
	.card-list li{
		color:$white;
	}
	.card-bx .change-btn:hover{
		color:var(--primary);
	}
	.invoice-card{
		&.bg-warning{
			background-color:#5b3c1f!important;
		}
		&.bg-success{
			background-color:#2a6729!important;
		}
		&.bg-info{
			background-color:#4c276a!important;
		}
		&.bg-secondary{
			background-color:#1c3e52!important;
		}
	}
	.user-list li{
		border-color:$dark-card;
	}
	.toggle-switch{
		color:$white;
	}
	.bar-chart{
		.apexcharts-text tspan{
			fill:#969ba0;
		}
		line{
			stroke:$d-border;
		}
	}
	.coin-card{
		background: #0f6a62; 
		background: -moz-linear-gradient(left,  #0f6a62 0%, #084355 100%); 
		background: -webkit-linear-gradient(left,  #0f6a62 0%,#084355 100%); 
		background: linear-gradient(to right,  #0f6a62 0%,#084355 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f6a62', endColorstr='#084355',GradientType=1 ); 
		.coin-icon{
			background:rgba($black,0.2);
		}
	}
	.accordion.style-1 .accordion-item,.invoice-list{
		border-color:$d-border;
	}
	.accordion.style-1 .accordion-header.collapsed {
		.user-info a,.user-info,&>span{
			color:$white;
		}
	}
	.ic-card {
		&>a{
			background:#25479f;
			&:first-child{
				border-color:#25479f;
			}
		}
		span{
			color:$white;
		}
	}
	table.dataTable thead th, table.dataTable thead td{
		border-color:$d-border!important;
	}
	.form-check .form-check-input{
		background:transparent;
		&:checked{
			background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
		}
	}
	.paging_simple_numbers.dataTables_paginate{
		//background:$d-bg;
	}
	.dataTables_info{
		color: #fff!important;
	
	}
	.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
	.dataTables_wrapper .dataTables_paginate span .paginate_button:hover{
		background:var(--primary);
		color:$white!important;
	}
	.dashboard-select{
		background:$dark-card;
	}
	.dashboard-select .list{
		background:$dark-card;
	}
	.dashboard-select .option:hover, .dashboard-select .option.focus, .dashboard-select .option.selected.focus{
		background:$d-bg;
	}
	.card-tabs.style-1 .nav-tabs{
		background:$dark-card;
	}
	.transaction-details{
		border-color:$d-border;
	}
	.description{
		color:$white;
	}
	.transaction-details .amount-bx{
		background:#3f250d;
		i{
			background:#8d3b0c;			
		}
	}
	.nice-select .option{
		background:$dark-card;
	}
	.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus{
		background:$dark-card;
	}
	.card-tabs.style-1,.coin-tabs .nav-tabs .nav-item .nav-link.active,.main-grid .feature-list li{
		border-color:$d-border;
	}
	
	.nice-select .list{
		background:$d-bg;
	}
	.table-search .input-group .input-group-text{
		background:$dark-card;
	}
	.dark-btn{
		svg{
			path{
				stroke: #fff;
			}
				
		}	
	}
	.bar-chart{
		.apexcharts-legend-text{
			color:$white!important;	
		}	
	}
	.Sales{
		tspan{
			fill:$white;	
		}	
	}
	.timeline-chart{
		rect{
			fill:$d-bg;	
		}	
	}
	.header-info{
		span{
			color:$white!important;	
		}	
	}
	.smallipop-instance.blue{
		color:#fff;
	}
	.property-map .smallipop.smallimap-mapicon .circle-marker,.table-list.style-1 td,.grid-tabs .nav-item .nav-link ,.header-profile .dz-calender,.datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown,.default-select,.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, .fc-scrollgrid, table,.fc-theme-standard td, .fc-theme-standard th{
		border-color:$d-border!important;
	}
	.property-features li{
		color:$white;	
	}
	.review-tabs-1{
		background:$dark-card;	
	}
	.tab-content .r-tab .review-para,.table-list .h5,.h5, h4, .h4,.facilities ul li a,.dropdown-item,.feature-list li,.grid-tabs .nav-item .nav-link,.datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev,.logo-compact{
		color:$white;
	}
	.table-list h5,.table-list div > span,.table-list a,h5.description-content p,.table strong,.list-group-item{
		color:$grey!important;
	}
	.grid-tabs .nav-item .nav-link,.guest-bx .carousel .carousel-control-prev, .guest-bx .carousel .carousel-control-next{
		background-color:$black!important;
	}
	.deznav .metismenu li > ul{
		//background:$dark-card;
	}
	.fc-direction-ltr .fc-daygrid-event.fc-event-end{
		color:$black;
		
		
	}
	.logo-compact{
		&.blk{
			display:none;
		}
		&.white{
			display:inline-block;
		}
	}
	.apexcharts-xaxis-label,
	.apexcharts-yaxis-label{
		tspan{
			stroke: $white;
		}
	}
	.apexcharts-gridlines-horizontal{
		line{
			stroke:$d-border;
		}
	}
	.table-list.style-1 .odd,.table-list.style-1 .even{
		background-color:$dark-card!important;
	}
	.prof-icon{
		path{
			stroke:$white;
		}
	}
	.datepicker-dropdown td.day{
		&:hover{
			background:$dark;
			color:$white;
		}
	}
	div.apexcharts-canvas.apexcharts-theme-dark {
		background: transparent !important;
		border-color:$white!important;
	}
	.header-right .search-area2 .input-group-text, .grid-info{
			background-color:$dark-card;
			border-color:$d-border;
	}
	.header-right .search-area2 .form-control,.search-area1{
		border-color:$d-border;
	}
	.dropdown{ 
		&.dropstart{
			.btn-link{
				svg{
					path{
						fill:$white;
						stroke:$white;
					}
				}
			}
		}
	}
	.card.main-card .concierge-bx .concierge-icon ul li .concierge-list-2{
		background-color:$d-bg;
	}
	.datepicker.datepicker-dropdown{
		background-color:$d-bg;
	}
	.menu-toggle .deznav .metismenu li > ul{
		background-color:$d-bg!important;
	}
	
	.btn-close{
		background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill='white' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
	}
	.fc-daygrid-dot-event{
		&:hover{
			background-color:$d-bg;
			color:$white!important;
		}
	}
	.accordion-primary .accordion-header.collapsed {
		background: var(--rgba-primary-1);
		border-color: var(--rgba-primary-1);
		color: #777777!important;
	}
	.accordion-header{
		color:$white!important;
	}
	.card-footer,.notification_dropdown .dropdown-menu-end .all-notification,.profile-media img{
		border-color:$d-border;
	}
	.accordion-item{
		background-color:transparent;
	}
	.crd-coloum{
		.apexcharts-canvas{
			path{
				stroke:$dark-card;
			}
		}
	}
	.squre{
		rect{
			
				fill:var(--primary);
			
		}
	}
	.coin-tabs .nav-tabs .nav-item .nav-link.active{
		background-color:$dark-card;
	}
	.card{
		&.tranding{
			background-color:unset;
		}
	}
	.table-bordered > :not(caption) > *{
		border-color:$d-border;
	}
	.ps__thumb-y{
		 background-color: rgba(255,255,255,0.5);
	}
	.page-logo{
		path{
			fill:$white;
		}
	}
	 .ck.ck-toolbar>.ck-toolbar__items{
		background-color:$d-bg;
		
	}
	.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
		background-color:$d-bg;
		border-color:$d-border;
	}
	
	.table.primary-table-bg-hover tbody tr td, .table.primary-table-bg-hover tbody tr th{
		border-color:$white!important;
		color:$white!important;
	}
	 .ck.ck-toolbar{
		background-color:$d-bg;
	}
	.ck.ck-editor__main>.ck-editor__editable{
		background-color:$d-bg;
	}
	.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused{
		border-color:$d-border;
	} 
	.form-check-input:checked:after{
		 //transform: translate(-50%, -50%) scale(1);
	}
	
	.ck.ck-editor__editable_inline{
		//border:1px solid $primary!important;
		//border-top:1px solid $primary!important;
	}
	.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners{
		border:1px solid $primary;
	}	
	.text-white .list-group-item {
		color:$white!important;
	} 
	.reservationChart{
		#SvgjsPath1705{
			fill:#fd7e14;
		}
		#SvgjsPath1706{
			stroke:#fd7e14
		}
	}
	.income-data{
		span{
			color:$white;
		}
	}
	.ps__rail-y{
		display:transparent;
	}
	.ps__rail-y:hover>.ps__thumb-y,
	.ps__rail-y:focus>.ps__thumb-y,
	.ps__rail-y.ps--clicking .ps__thumb-y {
		 background-color: rgba(255,255,255,0.5);
		width: 0.25rem;
	}
	.default-dropdown{
		.default-Select-btn{
			color: $white;
			background: $dark-card;
			border-color: $d-border;
		}
		.dropdown-menu{
			border: 1px solid #c2c2c2;
			.dropdown-item{				
				&:hover{
					background-color: rgba(110,110,110,0.1) !important;
				}
			}
			
		}
	}
	/* Summarnote Dark*/
	.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
		background-color: $dark-card;
	}
	.tox .tox-edit-area__iframe{
		background-color: $dark-card;
		color: $white;
	}
	.tox .tox-tbtn{
		color: $white;
		svg{
			fill: $white;
		}
	}
	.tox .tox-statusbar{
		background-color: $dark-card;
	}
	.tox .tox-statusbar__wordcount,
	.tox .tox-statusbar__path-item,
	.tox .tox-statusbar a{
		color: $white;
	}
	/* Summarnote Dark end*/
	.dzu-dropzone{
		background-color: $dark-card !important;
		border-color: $d-border !important;
		.dzu-inputLabel{
			background-color: $dark-card !important;
		}
	}
	.filter-pagination{
		background : $dark-card;
	}
	.filter-pagination .previous-button,
	.filter-pagination .next-button{
		background-color : $d-bg;
	}
	.table-index input[type=number]{
		background : $d-bg;
	}
}
	